import type { ItemModel, DataItemModel, RecordSourceOptions } from './types.ts';
import type DataObject from './DataObject.ts';

export default class DynamicLoading<T extends ItemModel = ItemModel> {
    private _dataObject: DataObject<T>;

    private _data: Map<number, DataItemModel<T>> = new Map();

    private _onDataLoaded?: (pClearData: boolean) => void;

    private _loadedPages = 0;
    private _buffer = 0;

    private _pagedData: DataItemModel<T>[] = [];

    private _currentStart = 0;
    private _loadDebounce: number | null = null;
    private _enabled = false;

    private _lastPageReached = false;

    get enabled() { return this._enabled; }
    set enabled(value) { this._enabled = value; }

    get onDataLoaded() { return this._onDataLoaded; }
    set onDataLoaded(value) {
        this._onDataLoaded = value;
    } 

    get lastPageReached() { return this._lastPageReached; }
    set lastPageReached(value) { this._lastPageReached = value; }

    get dataLength() {
        return this.rowCountLoaded ? this._dataObject.rowCount : this._dataObject.data.length;
    }

    get pagedData() { return this._pagedData; }

    get pageSize() {
        return this._dataObject.recordSource.maxRecords === -1
            ? 50
            : this._dataObject.recordSource.maxRecords;
    }
    // set pageSize(value) { /*this._dataObject.recordSource.maxRecords = value;*/ }

    get currentPage() {
        return Math.floor(this._currentStart / this.pageSize);
    }
    set currentPage(value) {
        this._currentStart = value * this.pageSize;
    }

    get currentStart() { return this._currentStart; }
    set currentStart(value) {
        this._currentStart = value < 0 ? 0 : value;
        if (this.enabled) { this.load(); }
    }

    get rowCountLoaded() {
        return this._dataObject.rowCount != null && this._dataObject.rowCount != -1;
    }

    constructor(pDataObject: DataObject<T>) {
        this._dataObject = pDataObject;
        this._initLastPageReached();
    }

    load() {
        if (this._loadDebounce) { window.clearTimeout(this._loadDebounce); }
        this._loadDebounce = window.setTimeout(() => {
            let skip: number | null = null;
            for (let i = this._currentStart; i <= this._currentStart + this.pageSize; i++) {
                if (!this._data.has(i)) { skip = i; break; }
            }
            if (skip == null) { return; }

            if (this._dataObject.rowCount == null || skip >= this._dataObject.rowCount) { return; }
            let maxRecords = 0;
            for (let i = skip; i <= skip + this.pageSize; i++) {
                if (!this._data.has(i)) { maxRecords += 1; }
            }
            this._dataObject.load({
                skip: skip,
                maxRecords: maxRecords + this._buffer,
            }).then(() => {
                this._pagedData.splice(0);
                const data = this._dataObject.storage.data.slice(this._currentStart, this.currentStart + this.pageSize);
                this._pagedData.push(...data);
            });
        }, 200);
    }

    /**
     * Check if all items from the given index are loaded
     * If some are missing, load the page
     */
    async loadFromIndex(pIndex: number, pPageSize: number) {
        let skip: number | null = null;
        let pageSize = pPageSize ?? this.pageSize;
        for (let i = pIndex; i<= pIndex + pageSize; i++) {
            if (this.rowCountLoaded) {
                if (i >= this._dataObject.rowCount ) {
                    break;
                }
            }
            if (!this._data.has(i)) {
                skip = i;
                break;
            }
        }
        if (skip == null) { return; }
        this._currentStart = skip;
        await this._dataObject.load({
            skip: skip,
            maxRecords: pageSize
        });
        this._pagedData.splice(0);
        const data = this._dataObject.storage.data.slice(pIndex, pIndex + pageSize);
        this._pagedData.push(...data);
    }

    async loadNextPage(pOptions?: RecordSourceOptions) {
        if (this._dataObject.state.isLoading ||
            !this._dataObject.state.isLoaded ||
            this._dataObject.state.isNextPageLoading ||
            this._lastPageReached) {
            return [];
        }

        if (this._dataObject.clientSideFiltering && this._dataObject.state.isLoaded) {
            return [];
        }
 
        if (pOptions == null) { pOptions = {}; }
        pOptions.maxRecords = this.pageSize + this._buffer;
        if (this._dataObject.storage.data.length === 0) {
            return this._dataObject.load(pOptions);
        }

        if (this._loadedPages === 0 && this._dataObject.storage.data.length > 0) {
            this._loadedPages = 1;
        }

        pOptions.skip = this._loadedPages * this.pageSize;
        const rowCount = this._dataObject.rowCount == -1 ? null : this._dataObject.rowCount; 
        if (rowCount != null && pOptions.skip >= (rowCount ?? 0)) {
            return [];
        }

        this._dataObject.state.isNextPageLoading = true;

        const data = await this._dataObject.load(pOptions);
        this._loadedPages++;
        this._dataObject.state.isNextPageLoading = false;
        if (data?.length === 0) { this._lastPageReached = true; }
        return data;
    }

    dataLoaded(pData: DataItemModel<T>[], pOptions?: RecordSourceOptions) {
        let clearStorage = false;
        if (pOptions?.skip === 0) {
            clearStorage = true;
            if (!this.rowCountLoaded) {
                this._lastPageReached = false;
            }
            this._currentStart = 0;
            this._loadedPages = 0;
            this._data.clear();
        }

        if (pData.length === 0 && (pOptions?.skip ?? 0) > 0) { return; }

        const newData: DataItemModel<T>[] = [];
        pData.forEach((item) => {
            const dynamicItem = this._data.get(item.index);
            if (dynamicItem == null && Object.keys(item.item).length > 0) {
                newData.push(item);
                this._data.set(item.index, item);
            }
        });

        this._dataObject.emit('DynamicDataLoaded', clearStorage, newData);

        if (this._onDataLoaded) { this._onDataLoaded(clearStorage); }
    }

    /** Get item by index from dynamic storage */
    getItem(pIndex: number) {
        if (!this.enabled) {
            return this._dataObject.data[pIndex];
        } else if (!this._data.has(pIndex)) {
            // TODO(Augustas): Fill in with Item promise?
            return undefined;
        } else {
            return this._data.get(pIndex);
        }
    }

    /** Set data on the dynamic items map */
    setItems(pData: DataItemModel<T>[], pStart: number = 0) {
        pData.forEach((item, index) => {
            this._data.set(index + pStart, item);
        });
    }

    private _initLastPageReached() {
        if (this.rowCountLoaded) {
            this._lastPageReached = this._dataObject.storage.data.length >= this._dataObject.state.rowCount!;
        } else {
            this._lastPageReached = false;
        }
    }
}